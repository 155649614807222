import {Root, WinnerCardNomination, WinnerCardContainer, WinnerCardImg, WinnerCardInfo,
     WinnerCardName, WinnerCardAuthor, WinnerCardAuthorLink,
      WinnerCardLink, WinnerCardNominationMobile, WinnerCardAward} from './styles';


const WinnerCard = (props) => {
    return (
        <Root>
            <WinnerCardNomination>{props.nomination}</WinnerCardNomination>
            <WinnerCardNominationMobile>{props.nominationMobile}</WinnerCardNominationMobile>
            <WinnerCardAward>{props.award}</WinnerCardAward>
            <WinnerCardContainer>
                <WinnerCardLink target='_blank' href={props.cardLink}><WinnerCardImg alt="winner-image" src={props.img}/></WinnerCardLink>
            </WinnerCardContainer>
            <WinnerCardInfo>
                <WinnerCardName>
                    {props.name}
                </WinnerCardName>
                <WinnerCardAuthor>
                    <WinnerCardAuthorLink target='_blank' href={props.authorLink}>{props.author}</WinnerCardAuthorLink>
                </WinnerCardAuthor>
            </WinnerCardInfo>
        </Root>
    )
}

export default WinnerCard;