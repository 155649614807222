import styled from 'styled-components/macro';

export const Root = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 0 0 30%;
`

export const WinnerCardNomination = styled.div`
    font-family: Palatino Linotype;
    font-size: 28px;
    line-height: 30px;
    text-align: center;
    opacity: 0.8;
    
    @media (max-width: 440px){
        display: none;
    }
`

export const WinnerCardNominationMobile = styled(WinnerCardNomination)`
    display: none;
    
    @media (max-width: 440px){
        display: block;
        font-size: 19px;
        margin-bottom: 2px;
    }
`

export const WinnerCardContainer = styled.div`
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 16px 26px;
    border: 1px solid rgba(171, 154, 144, 0.26);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 36px;
    width: 340px;
    height: 430px;
    border-radius: 12px;
    cursor: pointer;
    
    @media (max-width: 440px){
        padding: 12px 12px 16px;
        margin-bottom: 17px;
        width: 310px;
        height: 393px;
    }
`

export const WinnerCardImg = styled.img`
    width: 100%;
    height: 100%;
    display: block;
`

export const WinnerCardInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const WinnerCardName = styled.div`
    font-family: 'Montserrat';
    font-size: 24px;
    line-height: 22px;
    text-align: center;
    margin-bottom: 7px;
    opacity: 0.9;
    
    @media (max-width: 440px){
        font-size: 16px;
        margin-bottom: 4px;
    }
`

export const WinnerCardAuthor = styled(WinnerCardName)`
    margin-bottom: 0;
    opacity: 0.8;
    
    @media (max-width: 440px){
        font-size: 16px;
    }
`

export const WinnerCardAuthorLink = styled.a`
    font-family: 'Montserrat';
    font-size: 24px;
    line-height: 22px;
    text-align: center;
    margin-bottom: 0;
    opacity: 0.9;
    color: #000000;
    cursor: pointer;
    text-decoration: underline;
    
    @media (max-width: 440px){
        font-size: 16px;
    }
`

export const WinnerCardLink = styled.a`
    display: block;
    text-decoration: none;
`

export const WinnerCardAward = styled.div`
    font-family: Palatino Linotype;
    font-size: 22px;
    line-height: 22px;
    text-align: center;
    margin: 20px 0 16px;
    opacity: 0.9;

    @media (max-width: 440px){
        font-size: 15px;
        margin: 0 0 4px;
    }
`