import {  Fragment, useCallback } from 'react';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { io } from 'socket.io-client';
import { selectIsAuth } from '../../redux/slices/auth';
import axios from "../../utils/axios";
import styled from 'styled-components/macro';
import avatarUrl from './avatar.png';
import { useTranslation } from 'react-i18next';
import trashIcon from './../../assets/trash.svg'
import AttachmentModal from '../attachment-modal/attachment-modal';
import LoaderCircle from '../../ui-kit/loader-circle/loader-circle';

const CHAT_LIST_LOAD_API = '/chats';
const WS_API = 'wss://toobears.com/';

const Container = styled.div`


  @media (max-width: 577px) {
    padding: 0 15px;
  }
`

const ContainerChat = styled.div`
    display: flex;
    margin: 17px 0;
    height: 450px;
    background: rgba(0, 0, 0, 0.0001);
    border: 1px solid rgba(148, 148, 148, 0.5);
    border-radius: 10px;
    font-family: 'Montserrat';
    box-sizing: border-box;
    align-items: ${props => props.loading ? 'center' : 'normal'};
    justify-content: ${props => props.loading ? 'center' : 'flex-start'};

  @media (max-width: 577px) {
    padding-bottom: 2px;
    height: 70vh;
  }
`

const Messages = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex: 1 1 100%;
  padding: 6px 20px;
  box-sizing: border-box;
  text-align: right;

  @media (max-width: 577px) {
    display: ${props => props.isShowChat ? "flex" : "none"};
    padding: 6px 6px 6px 13px;
  }
`

const MyMessage = styled.div`
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    background: #F5F5F5;
    border-radius: 10px;
    align-self: flex-end;
    padding: 5px 10px;
    margin-bottom: 17px;
    text-align: left;

  @media (max-width: 577px) {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 12px;
  }
`

const CompanionWrapper = styled.div`
    display: flex;
    margin-bottom: 18px;

  @media (max-width: 577px) {
      margin-bottom: 10px;
  }
`
const CompanionAvatar = styled.img`
    width: 65px;
    height: 65px;
    border-radius: 50%;
    margin-right: 15px;
    object-fit: cover;
    flex: 0 0 auto;

  @media (max-width: 577px) {
    width: 48px;
    height: 48px;
    margin-right: 4px;
  }
`

const CompanionName = styled.div`
    font-weight: 700;
    font-size: 15px;
    margin-bottom: 5px;
    align-self: flex-start;
    text-align: left;

  @media (max-width: 577px) {
  }
`

const CompanionMessage = styled.div`
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    background: #F5F5F5;
    border-radius: 10px;
    padding: 5px 10px;
    line-break: anywhere;
    text-align: left;

  @media (max-width: 577px) {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
  }
`

const ChatList = styled.div`
    display: flex;
    flex-direction: column;
    overflow: auto;
    width: 250px;
    border-left: 1px solid rgba(148, 148, 148, 0.5);
    box-sizing: border-box;
    flex: 0 0 auto;

  @media (max-width: 577px) {
    display: ${props => props.isShowChat ? "none" : "flex"};
    border-left: none;
    width: 100%;
  }
`
const UserFromUserListWrapepr = styled.div`
    display: flex;
    background: ${props => props.active ? "rgba(213, 200, 200, 0.5)" : "rgba(0, 0, 0, 0.0001);"};
    padding: 10px;
    border-bottom: 1px solid rgba(148, 148, 148, 0.5);
    cursor: pointer;

  @media (max-width: 577px) {
    padding: 15px 10px;
  }
`
const Companion = styled.div`

  @media (max-width: 577px) {
  }
`

const MessageForm = styled.form`
    display: flex;
    flex-direction: column;
    padding-right: 250px;
    position: relative;

  @media (max-width: 577px) {
    display: ${props => props.isShowChat ? "flex" : "none"};
    padding-right: 0;
  }
`

const Previev = styled.div`
  position: absolute;
  right: 130px;
  @media (max-width: 577px) {
    right: 1px;
    top: 5px;
  }
`

const PrevievImg = styled.img`
  cursor: pointer;
  width: 110px;
  border-radius: 10px;
  max-height: 110px;
  object-fit: contain;
  @media (max-width: 577px) {
    width: 100px;
    max-height: 100px;
  }
`

const PrevievInner = styled.div`
  position: relative;
  
`

const TrashIcon = styled.img`
  position: absolute;
  display: block;
  width: 20px;
  right: 0px;
  bottom: 4px;
  background: white;
  cursor: pointer;
  border-radius: 0 0 5px 0;
`

const MessageArea = styled.textarea`
    box-sizing: border-box;
    border: 1px solid rgba(148, 148, 148, 0.5);
    border-radius: 10px;
    resize: none;
    height: 110px;
    padding: 10px 13px;
    margin-bottom: 18px;

  @media (max-width: 577px) {
    padding: 10px 100px 10px 13px;
  }
`
const FormBtnWrapper = styled.div`
    display: flex;
    justify-content: space-between;

  @media (max-width: 577px) {
  }
`
const AttachmentButton = styled.button`
    background: #F5F5F5;
    border-radius: 10px;
    height: 35px;
    padding: 8px 19px;
    cursor: pointer;
    border: none;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.8);
    pointer-events: none;
    font-weight: 400;

  @media (max-width: 577px) {
    width: 170px;
    font-weight: 400;
  }

  @media (max-width: 400px) {
    width: 140px;
    font-size: 12px;
    font-weight: 400;
  }
`

const SendButton = styled.button`
    background: rgba(254, 97, 3, 0.16);
    border-radius: 10px;
    height: 35px;
    padding: 8px 19px;
    cursor: pointer;
    border: none;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 400;

  @media (max-width: 577px) {
    width: 170px;
    font-weight: 400;
  }

  @media (max-width: 400px) {
    width: 140px;
    font-size: 12px;
    font-weight: 400;
  }
`

const InputStyled = styled.input`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0;

  &::file-selector-button {
    cursor: pointer;
  }
`
const InputContainerStyled = styled.div`
  position: relative;

  &::file-selector-button {
    cursor: pointer;
  }
`

const DateMessage = styled.div`
    font-size: 13px;
    color: #000000;
    padding: ${props => props.chatList ? "0" : "5px"};
    text-align: center;
    position: sticky;
    top: 0;
    display: inline-block;
    margin: auto;
    border-radius: 15px;
    background-color: ${props => props.chatList ? "transparent" : "white"};

  @media (max-width: 577px) {
  }
`
const Attachment = styled.img`
  display: block;
  max-width: 100%;
  max-height: 200px;
  cursor: pointer;
`

const ChatListBtn = styled.button`
  display: none;
  @media (max-width: 577px) {
    display: block;
    background: rgba(254, 97, 3, 0.16);
    border-radius: 10px;
    height: 35px;
    padding: 8px 19px;
    cursor: pointer;
    border: none;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.8);
    margin: 0 auto;
    width: 170px;
    font-weight: 600;
  }
`

const formatDate = (newDate) => {
  const months = {
    0: 'January',
    1: 'February',
    2: 'March',
    3: 'April',
    4: 'May',
    5: 'June',
    6: 'July',
    7: 'August',
    8: 'September',
    9: 'October',
    10: 'November',
    11: 'December',
  }
  const d = newDate
  const year = d.getFullYear()
  const date = d.getDate()
  const monthName = months[d.getMonth()]
  const formatted = `${date} ${monthName} ${year}`
  return formatted.toString()
}

const formatDateWithoutYear = (newDate) => {
  const months = {
    0: 'January',
    1: 'February',
    2: 'March',
    3: 'April',
    4: 'May',
    5: 'June',
    6: 'July',
    7: 'August',
    8: 'September',
    9: 'October',
    10: 'November',
    11: 'December',
  }
  const d = newDate
  const date = d.getDate()
  const monthName = months[d.getMonth()]
  const formatted = `${date} ${monthName}`
  return formatted.toString()
}

const Chat = () => {
    const [socket] = useState(io(
        WS_API,
        {
            transports : ['websocket']
        }
    ));
    const [chatList, setChatList] = useState([]);
    const { t } = useTranslation()
    const [isShowChat, setIsShowChat] = useState(false);
    const [isShowAttachment, setIsShowAttachment] = useState(null);
    const [currentChatContent, setCurrentChatContent] = useState([]);
    const [urlAttach, setUrlAttach] = useState(null);
    const currentChatIdRef = useRef(null);
    const toUserRef = useRef(null);
    const messagesRef = useRef(null);
    const { _id: myUserId } = useSelector(selectIsAuth);
    const token = window.localStorage.getItem('token');
    const [loading, setLoading] = useState(false)

    // const scrollToBottom = () => {
    //   if (messagesRef.current) {
    //     messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
    //   }
    // }

    const messagesByDate = currentChatContent?.reduce((acc, msg) => {
      const key = formatDate(new Date(msg.updatedAt));

      return {
        ...acc,
        [key]: [...(acc[key] || []), msg]
      }
    }, {})


    const onChatClick = useCallback((id) => {
      setLoading(true)
      currentChatIdRef.current !== id && axios.get(`${CHAT_LIST_LOAD_API}/${id}`).then((chatContent) => {
          setCurrentChatContent(chatContent.data);
          currentChatIdRef.current = id;
          toUserRef.current = chatList
              .find(({ _id }) => _id === currentChatIdRef.current)?.users
              .find(({ _id }) => _id !== myUserId);
          setIsShowChat(true);
          setLoading(false)
      })
  }, [chatList, myUserId])

    const sendMessage = ({ text, attachment }) => {
      if ((!text && !attachment) || !currentChatIdRef.current) {
          return
      }

      const data = {
          from: myUserId,
          chat: currentChatIdRef.current,
          to: toUserRef.current._id,
      }
      if (text) {
          data.text = text
      }
      if (attachment) {
          data.attachment = attachment
      }
      socket.emit('message', data);
      setCurrentChatContent((prev) => ([
        ...prev,
        {
          ...data,
          updatedAt: new Date().toISOString()
        }
      ]))
  }

    const attachFile = (e) => {
      let form = new FormData()
      form.append('images', e.target.files[0])

      axios.post('/upload', form).then(({ data }) => {
        e.target.value = '';
        setUrlAttach(data.urls[0]);
        e.target.value = null;
      }); 
    }

    const onMessageSend = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const text = e.currentTarget.elements.text.value;
        sendMessage({ text, attachment: urlAttach });
        e.currentTarget.elements.text.value = null;
        setUrlAttach(null);
    }

    useEffect(() => {
        // посылаем запрос на авторизацию в сокете
        socket.emit('connection', token)
        socket.on('auth_error', () => {
            alert('ошибка авторизации')
        })

        socket.on('message', (data) => {
            data.from === toUserRef.current._id && setCurrentChatContent((prev) => ([
                ...prev,
                {
                    ...data,
                    chat: currentChatIdRef.current,
                    updatedAt: new Date().toISOString(),

                }
            ]));
        })

        axios.get(CHAT_LIST_LOAD_API).then((chats) => {
            setChatList(chats.data);
            
            // chats.data.length && axios.get(`${CHAT_LIST_LOAD_API}${chats.data[0]._id}`).then((chatContent) => {
            //     setCurrentChatContent(chatContent.data.users[0]);
            //     // currentChatIdRef.current = chats.data[0]._id;
            //     // toUserRef.current = chats.data
            //     //     .find(({ _id }) => _id === chats.data[0]._id)?.users
            //     //     .find(({ _id }) => _id !== myUserId)
            // })
        })
    }, [ myUserId, socket, token]);

    useEffect(() => {
      chatList?.length &&
          onChatClick(chatList[0]._id)
    }, [chatList, onChatClick])

    // useEffect(() => {
    //   scrollToBottom();
    // }, [messagesByDate]);

    useEffect(() => {
      const scrollingElement = messagesRef.current;

      const config = { childList: true };

      const callback = function (mutationsList) {
        for (let mutation of mutationsList) {
          if (mutation.type === "childList") {
            scrollingElement.scrollTo(0, scrollingElement.scrollHeight);
          }
        }
      };

      const observer = new MutationObserver(callback);
      observer.observe(scrollingElement, config);
    }, [messagesRef]);

    useEffect(() => {
      const imgs = messagesRef.current ? messagesRef.current.getElementsByTagName('img') : [];

      [].forEach.call(imgs, function(img) {
          if (img.complete)
          imgLoaded();
          else
            img.addEventListener('load', imgLoaded, false);
      });
      
      function imgLoaded() {
        messagesRef.current && messagesRef.current.scrollTo(0, messagesRef.current.scrollHeight);
      }
    }, [messagesRef, messagesByDate])

    return (
        <Container className='container'>
            {isShowChat &&
              <ChatListBtn onClick={() => setIsShowChat(false)}>
                  {t('chatList')}
              </ChatListBtn>
            }
            <ContainerChat loading={loading}>
              {
                loading ? 
                  <LoaderCircle/> 
                    : 
                  <>
                    <Messages isShowChat={isShowChat} ref={messagesRef}>
                  {Object.entries(messagesByDate).map(([date, messages]) => (
                    <Fragment key={date}>
                      <DateMessage>
                        {date}
                      </DateMessage>
                      {messages.map(({ text, from, chat, attachment }, i) => {
                        const isMyMsg = from === myUserId;
                        const user = chatList
                            .find(({ _id }) => _id === chat).users
                            .find(({ _id }) => _id === from)

                        return isMyMsg ? (
                          <>
                            <MyMessage key={i}>
                              {attachment && <Attachment onClick={() => setIsShowAttachment(attachment)} src={`https://toobears.com/api${attachment}`} alt='Вложение'/>}
                              {text && text}
                            </MyMessage>
                          </>
                        ) : (
                            <>
                              <CompanionWrapper key={i}>
                                  <CompanionAvatar alt='avatar' src={user?.avatarUrl ? `https://toobears.com/api/${user?.avatarUrl}` : avatarUrl}/>
                                  <div>
                                      <CompanionName>{user?.username} {user?.surname}</CompanionName>
                                      <CompanionMessage>
                                        {attachment && <Attachment onClick={() => setIsShowAttachment(attachment)} src={`https://toobears.com/api${attachment}`} alt='Вложение'/>}
                                        {text && text}
                                      </CompanionMessage>
                                  </div>
                              </CompanionWrapper>
                            </>
                        )
                      })}
                    </Fragment>
                  ))}
                    </Messages>
                    <ChatList  isShowChat={isShowChat}>
                      {chatList.filter((chatList) => chatList.users.find(({_id})=> _id !== myUserId)).map(({ users, _id: chatId, updatedAt }) => {
                          const user = users.find(({ _id }) => _id !== myUserId);
                          return (
                              <UserFromUserListWrapepr
                                active={currentChatIdRef.current === chatId}
                                onClick={() => onChatClick(chatId)}
                                key={chatId}
                              >
                                <CompanionAvatar src={user.avatarUrl ? `https://toobears.com/api/${user.avatarUrl}` : avatarUrl}/>
                                <Companion>
                                  <CompanionName
                                      key={chatId}
                                  >
                                  {user.username} {user.surname}
                                  </CompanionName>
                                  <DateMessage chatList>
                                    {formatDateWithoutYear(new Date(updatedAt))}
                                  </DateMessage>
                                </Companion>
                              </UserFromUserListWrapepr>
                          )
                      })}
                    </ChatList>
                  </>
              }
            </ContainerChat>
            {currentChatIdRef.current && (
              <MessageForm className='messagePanel' onSubmit={onMessageSend} isShowChat={isShowChat}>
                {urlAttach && 
                  <Previev>
                      <PrevievInner>
                        <PrevievImg src={`https://toobears.com/api/${urlAttach}`} alt='attachment'/>
                        <TrashIcon onClick={() => setUrlAttach(null)} src={trashIcon} alt='trash' />
                      </PrevievInner>
                  </Previev>
                }
                <MessageArea placeholder={t('yourMessage')} name="text" />
                <FormBtnWrapper>
                    <InputContainerStyled>
                      <AttachmentButton type='button'>{t('attachImg')}</AttachmentButton>
                      <InputStyled name="attachment" type="file" accept="image/*" onChange={attachFile} />
                    </InputContainerStyled>
                    <SendButton type='submit'>{t('send')}</SendButton>
                </FormBtnWrapper>
            </MessageForm>
            )}
            {isShowAttachment &&
              <AttachmentModal attachment={isShowAttachment} onClose={() => setIsShowAttachment(false)}/>
            }
        </Container>
    )
}

export default Chat;
