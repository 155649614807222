import React, { useCallback, useState, useEffect, useMemo } from 'react'
import styled from 'styled-components/macro'
import { useParams } from 'react-router-dom'
import axios from '../../utils/axios'
import {Link} from 'react-router-dom'
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { FreeMode, Navigation, Thumbs } from "swiper";
import { useTranslation } from 'react-i18next'
import MessageModal from '../MessageModal/MessageModal'
import messageSvg from '../MessageModal/Message.svg';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { selectIsAuth } from '../../redux/slices/auth'
import BuyPage from '../BuyPage/BuyPage'
import SuccessOrder from '../BuyPage/sussecOrder/successOrder'
import { Fragment } from 'react'
import defaultToy from './../../assets/defaultToy.png'
import CardPreview from './card-preview'
import defaultAvatar from './../../assets/cardPhoto.png'
import { OLD_KEYS_COUNTRY_MAP } from '../../const/old-keys-country-map/old-keys-country-map'
import countriesLanguages from '@hotosm/iso-countries-languages'
import LoaderModal from '../loader-modal/loader-modal'

const SectionTop = styled.div`
    width: 1150px;
    display: flex;
    margin: 5rem auto;
    border-radius: 1px solid;
    
    .swiperColorNavigation {
      --swiper-navigation-color: #fff;
      --swiper-pagination-color: #fff;
      height: 100%;
    }

    @media (max-width: 577px){
      width: 100%;
      flex-direction: column;

      .swiperColorNavigation {
        --swiper-navigation-color: transparent;
      }
    }
`

const Like = styled.div`
display: flex;
width: fit-content;
padding-right: 1rem;
height: 55px;
position: absolute;
margin-top: -3.7rem;
right: 0px;
bottom: 0px;
border-radius: 20px 0 0 0;
z-index: 255;
background-color: white;
transition: 0.5s ease;
opacity: 0;

h3{
    margin-top: 20px;
    margin-left: -25px;
    font-weight: 400;
    font-size: 18px;
}
img{
    opacity: 0.7;
}
.heart {
  width: 100px;
  height: 100px;
  background: url("https://cssanimation.rocks/images/posts/steps/heart.png") no-repeat;
  background-position: 0 0;
  cursor: pointer;
  transition: background-position 1s steps(28);
  transition-duration: 0s;
  margin-left: -20px;
  margin-top: -20px;
  
  &.is-active {
    transition-duration: 1s;
    background-position: -2800px 0;
  }

  @media (max-width: 577px){
    margin-top: -20px;
  }
}
  @media (max-width: 577px){
    bottom: 0;
  }
`

const SectionDesc = styled.section`
width: 450px;
position: relative;
margin-left: 2rem;

@media (max-width: 577px){
  width: 90%;
  margin-top: 2rem;
}
`

const Head = styled.div`
color: #786B63;
border-bottom: 1px solid rgba(171, 154, 144, 0.5);
font-size: 30px;
font-weight: 500;
font-family: 'Palatino';

@media (max-width: 577px){
  width: 90%;
}
`
const Desc = styled.div`
word-break: break-word;
font-size: 13pt;
line-height: 30px;
margin-top: 10px;
list-style-image: rgba(0, 0, 0, 0.2);
height: fit-content;

ul{
    margin-top: 1rem;
    margin-left: 1rem;
}
`

const Name = styled.div`
margin-top: 10px;
font-weight: 400;
font-size: 18px;
`
const Price = styled.div`
margin-top: 1rem;
font-size: 26px;
color: #D8946B;
`
const Delivery = styled.div`
  margin-left: 1px;
  margin-top: 1rem;
  font-size: 13pt;
  display: flex;
  align-items: center;
`
const Btn = styled.button`
cursor: pointer;
width: 200px;
height: 60px;
font-weight: 600;
border-radius: 10px;
color: ${props => props.sold ? 'rgba(0, 0, 0, 0.3)' : props.reserved ? 'rgba(0, 0, 0, 0.3)' : 'white'};
background:${props => props.sold ? 'rgba(213, 200, 200, 0.7)' : props.reserved ? 'rgba(200, 211, 213, 0.7)' : '#FE6103'};
outline: none;
border: none;
font-size: 20px;
filter: saturate(90%);
opacity: ${props => props.sold ? '1' : props.reserved ? '1' : '0.7'};
font-family: 'Montserrat';
box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.2);
margin-right: 10px;

@media (max-width: 577px){
  padding: 5px;
  font-size: 18px;
}
`


const BtnCont = styled.div`
display: flex;
margin-top: 50px;
`

const SectionPayment = styled.div`
width: 1180px;
margin: 0 auto;
border-top: 1px solid rgba(145, 114, 114, 0.2);
border-bottom: 1px solid rgba(145, 114, 114, 0.2);
height: 70px;

@media (max-width: 577px){
  width: 90%;
  margin-bottom: 0rem;
}
`

const TabContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 5px;
`

const Tab = styled.button`
    font-family: 'Palatino';
    font-size: 28px;
    opacity: 0.75;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0 10px;
    margin-right: 40px;
    border-bottom:  ${props => props.isActive && '5px solid rgba(254,97,3,0.3)'};   

    &:last-child {
      margin-right: 0;
    }
`
const Describe = styled.div`
  top: 1rem;
  width: 1180px;
  font-size: 17pt;
  margin: 0 auto;
  position: relative;
  left: 20px;
  padding: 0 10px;

  @media (max-width: 577px){
    width: 90%;
    margin: 0 0rem;
    word-break: break-word;
  }
`

const User = styled.div`
  margin: 45px auto;
  height: 280px;
  width: 350px;
  border: 1px solid rgba(171, 154, 144, 0.6);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 577px){
    width: 90%;
  }
  a {
    margin-bottom: 5px;
  }
`

const Avatar = styled.div`
margin: 1rem auto;
width: 98px;
height: 99px;

img{
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}
`

const NameUser = styled.div`
font-weight: 700;
font-size: 13pt;
text-align: center;
opacity: 0.8;
`

const Subs = styled.div`  
  font-size: 11pt;
`

const Location = styled.div`
width: 280px;
height: 17px;
display: flex;
justify-content: center;
margin-bottom: 5px;
padding: 0 15px;

img{
    width: 18px;
    height: 18px;
    display: block;
}
h4{
    display: block;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 13px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@media (max-width: 577px){
    padding: 0 5px;
}
` 

const ButtonSub = styled.button`
  border: none;
  width: 300px;
  margin: 1rem 1.7rem;
  height: 40px;
  outline: none;
  border-radius: 10px;
  color: black;
  cursor: pointer;
  opacity: ${props => props.disabled ? '0.3' : '1'}; ;
  background: ${props => props.disabled || props.unsubBackground ? 'rgba(207, 157, 128, 0.2)' : 'rgba(254, 97, 3, 0.2)'};
  outline: none;
  border: none;
  font-size: 20px;
  filter: saturate(90%);
  margin-top: 1rem;
  font-family: 'Montserrat';
  
  @media (max-width: 577px){
    width: 250px;
  }
`
const Button = styled.button`
  width: 200px;
  height: 35px;
  font-size: 15px;
  border: none;
  border-radius: 10px;
  font-family: 'Montserrat';
  background-color: rgba(189, 169, 158, 0.2);
  padding-right: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;

  img{
      transform: rotate(-90deg);
      width: 20px;
      display: block;
      opacity: 0.5;
  }

  @media (max-width: 577px){
    right: 0;
    position: relative;
  }
`

const ButtonWrapper = styled.div`
  max-width: 1400px;
  margin: 0 auto 30px;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 577px){
    margin: 0 auto 30px;
    justify-content: center;
  }
`

const MarketPart = styled.div`
  display: grid;
  place-items: center;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 35px;
  max-width: 1400px;
  margin: 0 auto;

  @media (max-width: 577px){
    margin: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    padding: 0 5px;
  }
`

const BtnQuestion = styled.button`
  padding: 9px 17px;
  border: none;
  cursor: pointer;
  background: rgba(213, 200, 200, 0.6);
  color: #000000;
  opacity: 0.6;
  font-size: 15px;
  font-weight: 400;
  height: 36px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  margin-top: auto;
  font-family: 'Montserrat';
`

const ImageMessage = styled.img`
  margin-right: 12px;
`

const SmallImgSwiper = styled.img`
  border-radius: 8px;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  height: 100%;
  width: 100%;
  object-fit: cover;
`

const CardImg = styled.div`
  width: 650px;
  border: 1px solid rgba(171, 154, 144, 0.5);
  padding: 10px 10px 15px;
  display: flex;
  flex-direction: column;
  height: 950px;
  border-radius: 20px;
  box-sizing: border-box;

  &:hover .like {
    opacity: 1
  }

  .swiperCard {
      display: flex;
      justify-content: center;
      border-radius: 15px;
      overflow: hidden;
    }

  /* img{
    position: relative;
    width: 630px;
    height: 750px;
    border-radius: 10px;
    object-fit: contain;
  } */

  .slide {
    display: flex;
    justify-content: center;
    width: 100px;
  }

  .slide:first-child {
    margin-left: 0;
  }

  .slide:last-child {
    margin-right: 0;
  }

  @media (max-width: 577px){
    width: 90%;
    height: 640px;
    margin: auto;

    .swiperCard {
      display: flex;
      justify-content: center;
    }
  }
`

const Activity = styled.div`
  margin-top: 1rem;
  height: 70px;
  display: flex;
  img{
      width: 30px;
      height: 30px;
  }
`

const MainCargImage = styled.img`
  border-radius: 15px;
  position: relative;
  margin: auto;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`

const MainSwiper = styled.div`
  display: block;
  flex: 1 1 100%;
  height: auto;
  min-height: 0;

  @media (max-width: 1024px){
  }

  @media (max-width: 414px){
  }
`

const SmallSwiper = styled.div`
  display: flex;
  flex: 0 0 auto;
  height: 170px;

  @media (max-width: 1024px){
  }

  @media (max-width: 414px){
    height: 140px;
  }
`

export const Card = () => {

  const navigate = useNavigate();
  const {id} = useParams();
  const [buyProcess, setBuyProcess] = useState('card');
  const isAuth = useSelector(selectIsAuth)
  const [card, setCard] = useState(null);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [allCard, setAllCard] = useState([])
  const params = useParams();
  const [userId, setUserId] = useState(null)
  const { t, i18n } = useTranslation();
  const [defaultModalValue, setDefaultModalValue] = useState('');
  const [modalType, setModalType] = useState(null);
  const [isShowPayment, setIsShowPayment] = useState(true);
  const [isActiveTab, setActiveTab] = React.useState('pay');
  const [subs, setSubs] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const isLiked = useMemo(() => Boolean(card && card.like.includes(isAuth && isAuth._id)), [card, isAuth])

  const country = countriesLanguages.getCountry(i18n.language, OLD_KEYS_COUNTRY_MAP[card?.author.country]  || card?.author.country);

  const translatedCountry = card?.author.country ? (country || t('russia')) : t('russia')

  const handleChangeActiveTab = () => {
    if (isActiveTab === 'pay') {
      setActiveTab('delivery')
      setIsShowPayment((prev) => !prev)
    } else{
      setActiveTab('pay')
      setIsShowPayment((prev) => !prev)
    }
  }

  const fetchCard = useCallback(async () => {
    const { data } = await axios.get(`/market/${params.id}`)
    setCard(data)

    setUserId(data.author._id)
  }, [params.id]);

  useEffect(() => {
    fetchCard()
  }, [fetchCard])

  const userCards = useCallback(async () =>{
    if (userId != null) {
      const { data } = await axios.get(`/market/cards/${userId}`)
      setAllCard(data.filter((card) => !!card))
    }
  }, [userId])
  
  useEffect(() => {
    userCards()
  }, [userCards])

  
  const fetchMySubs = async () => {
    try{
        const { data } = await axios.get(`users/me/subscribed`)
        setSubs(data)
    } catch (error) {
        console.log(error)
    }
  }

  useEffect(() => {
    fetchMySubs();
  }, [])


  const isSub = subs.find((item) => item._id === card?.author._id);
  const handleSubscribe = async() => {
      if (isSub) {
        await axios.delete(`/users/subscribe/${userId}`)
        fetchMySubs()
      } else {
        await axios.patch(`/users/subscribe/${userId}`)
        if (isAuth.role === 'User') {
          navigate('/account/Subs');
        } else {
          navigate('/account/Favorite/subs');
        }
      }
  }

  const handleClick = () => {
    if (isAuth === null) {
      navigate('/auth/login')
      return;
    }
    
    if (!isLiked) {
      axios.patch(`/market/${id}/like`);
      setCard((prev) => ({
        ...prev,
        like: [
          ...prev.like,
          isAuth._id
        ],
        likeCount: prev.likeCount + 1
      }))
    } else {
      axios.delete(`/market/${id}/likeremove`);
      setCard((prev) => ({
        ...prev,
        like: prev.like.filter((id) => id !== isAuth._id),
        likeCount: prev.likeCount - 1
      }))
    }
  }

  if (!card, userId === null) {
    return (
      <LoaderModal/> 
    )
  }

  const sendMessage = async (text, attachment) => {
    await axios.post('/chats', {
      user: card.author._id,
      text,
      attachment
    });

    navigate('/account/Message');
  }

  const placeOrder = async ({ message, ...rest }) => {
      setIsLoading(true);
      await axios.post('/orders', {
        card: card._id,
        ...rest,
        message
      });
  
      await axios.post('/chats', {
        user: card.author._id,
        text: message
      });
      setIsLoading(false);

      setBuyProcess('success');
  }

  const navigateToLogin = () => {
    navigate('/auth/login');
  }


  return (
    <>
    
    {buyProcess === 'buy' &&
        <BuyPage isLoading={isLoading} onSend={placeOrder} card={card}/>}
    {buyProcess === 'card' &&
      <>
      <SectionTop>
      <CardImg>
          <MainSwiper>
            <Swiper
              className='swiperColorNavigation'
              loop={true}
              spaceBetween={5}
              navigation={true}
              thumbs={{ swiper: thumbsSwiper }}
              modules={[FreeMode, Navigation, Thumbs]}
            >
              <Like className='like'>
                <div className='placement'>
                    <div className={isLiked ? 'heart is-active' : 'heart'} onClick={handleClick} />
                </div>
                <h3>{card.likeCount !== 0 && card.likeCount}</h3>
              </Like>
              {card.imgUrl?.map((obj, index) => (
              <SwiperSlide className='swiperCard' key={index}>
                <MainCargImage alt='artwork'  src={`https://toobears.com/api/${card.imgUrl[index]}`} />
              </SwiperSlide>
              ))}
            </Swiper>
          </MainSwiper>
          <SmallSwiper>
            <Swiper
              onSwiper={setThumbsSwiper}
              style={{
                'display': 'flex',
                'margin-top': '20px'
              }}
              loop={false}
              spaceBetween={7}
              slidesPerView="auto"
              // freeMode={false}
              watchSlidesProgress={false}
              modules={[ Navigation, Thumbs]}
              className="mySwiper"
            >
              {card.imgUrl?.map((obj, index) => (
                <SwiperSlide className='slide' key={index}>
                  <SmallImgSwiper alt='img' className='image0' src={`https://toobears.com/api/${card.imgUrl[index]}`} />
                </SwiperSlide> ))}
            </Swiper>
          </SmallSwiper>
          {/* <Activity>
          </Activity> */}
      </CardImg>
      <SectionDesc>
          <Head>
              {card.name}
          </Head>
          <Name>
            by <Link to={`/${card.author.shortlink || card.author._id}`}>{card.author.username} {card.author.surname}</Link>
          </Name>
          <Desc>
              {card.description}
              <ul>
                  <li>{t('size')} {card.size}cm</li>
                  <li>{t('cathegory')}  {card.cathegory}</li>
                  <li>{t('onlyone')}</li>
              </ul>
          </Desc>
          {card.status === '1' ?
            <>
            <Price>
              <div translate="no">{t('price')}: {card.price + '.00' + ' ' + card.currency}</div> 
            </Price>
            <Delivery><span style={{fontWeight: 400, fontSize: '14pt'}}>{t('delivery')}: &nbsp;</span>
            {card.delivery.length > 0 ?
              card.delivery[0].destination?.map((obj, index) => {
                return <Fragment key={index}>
                  <div>{obj} - <span translate='no' style={{fontWeight:'400'}}>{card.delivery[0].destPrice[index] === '0' || card.delivery[0].destPrice[index] === '' ? <>{t('free')}</> : card.delivery[0].destPrice[index] + ' ' + card.delivery[0].destCurrency[index]}</span>
                    &nbsp;</div>&nbsp;
                  </Fragment>
              }) :
              card.delivery[0].destination?.map((obj, index) => {
                return <Fragment key={index}>
                  <div>{obj} - <span translate='no' style={{fontWeight:'400'}}>{card.delivery[0].destPrice[index] === '0' || card.delivery[0].destPrice[index] === '' ? <>{t('free')}</> : card.delivery[0].destPrice[index] + ' ' + card.delivery[0].destCurrency[index]}</span>
                    &nbsp;</div>&nbsp;
                  </Fragment>
              })
            }
            </Delivery>
            </>
            :
            <></>
          }
          {isAuth === null  ? 
            <BtnCont>
              <Btn disabled={card.status === '3' || card.status === '2'} reserved={card.status === '3'} sold={card.status === '2'} 
                onClick={navigateToLogin}
              >
                  <span>
                    {card.status === '1' ?
                      <>{t('buy')}</> :
                      card.status === '2' ?
                      <><>{t('soldOut')}</></> :
                      <>{t('reserved')}</>
                    }
                  </span>
              </Btn>
              <BtnQuestion translate='no' onClick={navigateToLogin}>
                <ImageMessage alt='message' src={messageSvg}/> {''} {t('ask')}
              </BtnQuestion>
            </BtnCont>
            :
            <BtnCont>
              <Btn translate='no' disabled={card.status === '3' || card.status === '2'} reserved={card.status === '3'} sold={card.status === '2'} 
                onClick={async () => {
                  await setBuyProcess('buy');
                  window.scrollTo(0, 0);
                }}
              >
                  <span>
                  {card.status === '1' ?
                    <>{t('buy')}</> :
                    card.status === '2' ?
                    <><>{t('soldOut')}</></> :
                    <>{t('reserved')}</>
                  }
                  </span>
              </Btn>
              <BtnQuestion translate='no' onClick={() => {
                setDefaultModalValue('');
                setModalType('SEND_MESSAGE');
              }}>
                <ImageMessage alt='message' src={messageSvg}/> {''} {t('ask')}
              </BtnQuestion>
            </BtnCont>
          }
      </SectionDesc>
      </SectionTop>
      <SectionPayment>
      <TabContainer>
          <Tab isActive={isActiveTab === 'pay'} onClick={
            () =>  handleChangeActiveTab()
            }>
                    {t('pay')}
          </Tab>
          <Tab isActive={isActiveTab === 'delivery'} onClick={
            () =>  handleChangeActiveTab()
            }>
                      {t('delivery')}
          </Tab>
      </TabContainer>
      </SectionPayment>
      <Describe>
        {isShowPayment ? 
          (<>
            {card.paymentDescription}
          </>)
            :
          (<>{card.deliveryDescription}</>)}
      </Describe>
      <User>
          <Link to={`/${card.author.shortlink || card.author._id}`}>
              <Avatar>
                <img alt='avatar' src={`https://toobears.com/api/${card.author.avatarUrl}`}/>
              </Avatar>
              <NameUser>{card.author.shopname}</NameUser>
          </Link>
          <Location>
              <img src="https://svgshare.com/i/iCj.svg" alt="location"/>
              <h4>{translatedCountry}</h4>
          </Location>
          <Subs>{card.author.subsCount} {t('subs')}</Subs>
          {isAuth === null  ? 
              <ButtonSub onClick={navigateToLogin}>
                  {t('subscribe')}
              </ButtonSub>
                :  
              <ButtonSub unsubBackground={isSub} onClick={handleSubscribe} disabled={card.author._id === isAuth._id}>
                {isSub ? t('unsub') : t('subscribe')}
              </ButtonSub>
          }
      </User>
      <ButtonWrapper>
        <Link to={`/${card.author.shortlink || card.author._id}`}>
            <Button>
              <div>
                {t('allWorks')}
              </div>
              <img alt='allWork' src='https://svgshare.com/i/jeQ.svg' />
            </Button>
        </Link>
      </ButtonWrapper>
      <MarketPart>
        {userId != null ? allCard.filter((card) => !!card).sort((a,b) =>  b.createdAt.localeCompare(a.createdAt)).slice(0, 4).map((obj, index) =>
          (
            <CardPreview
              altWork={obj.name} 
              imageWork={obj.imgUrl ?`https://toobears.com/api/${obj.imgUrl[0]}` : defaultToy}
              avatar={`https://toobears.com/api/${obj.author.avatarUrl}` || defaultAvatar}
              shopName={obj.author.shopname || `${obj.author.username} ${obj.author.surname}`}
              nameWork={obj.name}
              price={obj.price + ' ' + obj.currency}
              size={obj.size + 'cm'}
              likeActive={obj.like.includes(isAuth && isAuth._id)}  
              status={obj.status}  
              link={`/card/${obj._id}`}
              key={obj._id}
          />
          )) : <div>{t('load')}</div>  }
      </MarketPart>
      
    </>
    }
    {buyProcess === 'success' &&
      <SuccessOrder card={card}/>
    }
    {modalType &&
      <MessageModal
        onSend={sendMessage}
        defaulValue={defaultModalValue}
        to={`${card.author.username} ${card.author.surname}`}
        theme={card.name}
        onClose={() => {setModalType(null)}}
      />
    }
    </>
  )
}