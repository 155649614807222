import React, { useState } from 'react'
import img from '../../../assets/footerlogo.png'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import instagramIcon from './../../../assets/instagram.png'
import facebookIcon from './../../../assets/facebook.png'
import telegramIcon from './../../../assets/telegram.png'
import pinterestIcon from './../../../assets/pinterest.png'
import ContactModal from '../../information-pages/modal/contact-modal'



const Section = styled.footer`
width: 100%;
color: #000;
background-color: rgba(234, 226, 218, 0.6);
flex-shrink: 0;
position: relative;
margin-top: 40px;

@media (max-width: 577px){
    margin-top: 40px;
  }
`
const Container = styled.div`
  max-width: 80%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 auto;
  padding: 35px 400px 0;
  position: relative;

  @media (max-width: 577px){
    display: block;
    padding: 35px 20px 0;
  }
`
const LogoCont = styled.div`
justify-content: center;
align-self: center;
position: relative;
margin-top: -110px;

  img{
      width: 300px;
      height: 130px;
      filter: saturate(90%);
  }

  @media (max-width: 577px){
    margin: 0;
    img{
      width: 260px;
      height: 110px;
      filter: saturate(90%);
    }
  }
`

const FooterText = styled.div`
  display: flex;
  padding: 20px 0;
  width: 100%;
  justify-content: flex-end;

  @media (max-width: 577px){
    padding: 0;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
`
const FooterItem = styled.div`
  color: #010101;
  margin-right: 130px;
  padding: 50px 0 0;

  &:first-child {
    margin-left: 51px;
  }

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: 577px){
    padding: 0;

    &:first-child {
      margin-left: 0;
      margin-bottom: 20px;
    }

    &:last-child {
      margin-bottom: 30px;
    }
  }
`
const FooterItemTitle = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 42px;
  opacity: 0.8;
  font-family: 'Palatino Linotype';
  white-space: nowrap;
`
const FooterItemLink = styled(Link)`
  display: block;
  text-decoration: none;
  text-transform: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 42px;
  cursor: pointer;
`

const FooterItemBtm = styled.button`
  display: block;
  text-decoration: none;
  text-transform: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 42px;
  cursor: pointer;
  background: none;
  border: none;
  font-family: "Montserrat",sans-serif;
`

const SocailWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0 70px;
   a {
    margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
   }
   img {
        width: 30px;
      }

   @media (max-width: 577px){
      img {
        width: 25px;
      }
   }
`

const Footer = () => {
  const [isOpenModal, setIsOpenModal] = useState();
  const { t } = useTranslation();
  return (
  <Section>
    <Container>
      <LogoCont>
        <img src={img} alt='logo'/>
      </LogoCont>
      <FooterText>
        <FooterItem>
          <FooterItemTitle>
            {t('aboutUs')}
          </FooterItemTitle>
          <FooterItemLink to='/ourHistory'>
            {t('ourHistory')}
          </FooterItemLink>
          <FooterItemBtm onClick={() => setIsOpenModal(true)}>
            {t('contacts')}
          </FooterItemBtm>
          <FooterItemLink to='/ourPartners'>
            {t('OurPartners')}
          </FooterItemLink>
        </FooterItem>
        <FooterItem>
          <FooterItemTitle>
            {t('legalInfo')}
          </FooterItemTitle>
          <FooterItemLink to='/termsOfUse'>
            {t('termsOfUse')}
          </FooterItemLink>
          <FooterItemLink to='/privacyPolicy'>
            {t('privacyPolicy')}
          </FooterItemLink>
          <FooterItemLink to='/recomendation'>
            {t('usagRecommendation')}
          </FooterItemLink>
        </FooterItem>
      </FooterText>
    </Container>
    <SocailWrapper>
          <Link to={`//instagram.com/toobears_com?r=nametag`} target='_blank'>
              <img src={instagramIcon} alt='instagram'/>
          </Link>
          <Link to={`//www.facebook.com/toobearscom`} target='_blank'>
              <img src={facebookIcon} alt='instagram'/>
          </Link>
          <Link to={`//t.me/+dlx-mLDtYKU0Mzgy `} target='_blank'>
              <img src={telegramIcon} alt='instagram'/>
          </Link>
          <Link to={`//www.pinterest.com/`} target='_blank'>
              <img src={pinterestIcon} alt='instagram'/>
          </Link>
    </SocailWrapper>
    {isOpenModal &&
        <ContactModal onClose={() => setIsOpenModal(false)} />
    }
  </Section>
  )
}

export default Footer