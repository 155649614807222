import React from 'react'
import styled from 'styled-components/macro'
import logo from '../../../assets/logo-bears.png'

const ImgContainer = styled.div`
position: relative;
margin-top: 0.5rem;
img {
    height: 44px;
    filter: saturate(70%);
}

@media (max-width: 577px){
  img{
    height: 30px;
    margin-left: -2.5rem
  }
}

@media (max-width: 340px){
  img{
    height: 25px;
  }
}
`

const Logo = () => {
  return (
    <ImgContainer>
        <img src={logo} alt="logo" />
    </ImgContainer>
  )
}

export default Logo