import { useTranslation } from 'react-i18next'
import {Root, BannerBear, MainText, MainTextTitle, GreetingTitle, GreetingTitleMobile, WinnersTitle, WinnersIcon, TestGradText, WinnerText, WinnersTitleMobile, TestGradTextSecond
} from '../styles';
import {MarketPart} from './../online-show-works/styles';
import winIcon from './assets/win-icon.png'
import naturalWinner from './assets/natural-look.png'
import fantasyWinner from './assets/fantasy-look.png'
import originalWinner from './assets/original-winner.png'
import adorableWinner from './assets/adorable-winner.png'
import creativeWinner from './assets/creative-winner.png'
import choiceWinnerIlka from './assets/choice-IlkaIMG_6772 1.png'
import choiceWinnerToobears from './assets/choice-toobears.png'

import WinnerCard from './winner-card/winner-card';


const OnlineShowFinish = () => {
    const { t } = useTranslation();

    return (
        <Root>
            <BannerBear></BannerBear>
            <GreetingTitle>{t('FinishShow')}</GreetingTitle>
            <GreetingTitleMobile>{t('FinishShow')}</GreetingTitleMobile>
            <MainText>
                <MainTextTitle><i>{t('ToobearShow')} -</i> </MainTextTitle>
                {t('ToobearShowDesc')}
            </MainText>
            <MainText>{t('ShowDesc2')}</MainText>
            <WinnersTitle>
                <TestGradText>
                    {t('WinnerShowTitle1')}<br/>
                    <TestGradTextSecond>{t('WinnerShowTitle2')}</TestGradTextSecond>
                </TestGradText>
            </WinnersTitle>
            <WinnersTitleMobile>
                <TestGradText>{t('WinnerShowTitleMobile1')}</TestGradText>
                <br/>{t('WinnerShowTitleMobile2')}
            </WinnersTitleMobile>
                <MarketPart>
                    <WinnerCard
                        nomination={<>The most natural <WinnersIcon alt='win' src={winIcon}/> <br/>look</>}
                        img={naturalWinner}
                        award={t('prizeExclusive')}
                        name="Marquis the Cat"
                        author="Elena Boyko"
                        authorLink="/theworldoflittleanimals"
                        cardLink="/card/66daa17a033665ef0e94342f"
                        nominationMobile={<>The most natural look <WinnersIcon alt='win' src={winIcon}/></>}
                    />
                    <WinnerCard
                        nomination={<>The most fantasy <WinnersIcon alt='win' src={winIcon}/> <br/>look</>}
                        img={fantasyWinner}
                        award={t('prizeExclusive')}
                        name="Forest Beau Monde"
                        author="Julia Melnikova"
                        authorLink="/workshopofyuliamelnikova"
                        cardLink="card/66da97ec6fd69073009a3668"
                        nominationMobile={<>The most fantasy look<WinnersIcon alt='win' src={winIcon}/></>}
                    />
                     <WinnerCard
                        nomination={<>The most original <WinnersIcon alt='win' src={winIcon}/> <br/>composition</>}
                        img={originalWinner}
                        award={t('prizeExclusive')}
                        name="Scary baby teddy owl Boo"
                        author="Ekaterina Nebykova"
                        authorLink="/ILKAteddy"
                        cardLink="/card/66da9923033665ef0e9422b3"
                        nominationMobile={<>The most original <WinnersIcon alt='win' src={winIcon}/> <br/>composition</>}
                    />
                    <WinnerCard
                        nomination={<>The most adorable bear<WinnersIcon alt='win' src={winIcon}/> <br/>of the show</>}
                        award={t('prizeExclusive')}
                        img={adorableWinner}
                        name="Lars and Quentin"
                        author="Nattie Kelly"
                        authorLink="/nattiesteddies"
                        cardLink="/card/66daa0f6033665ef0e943354"
                        nominationMobile={<>The most adorable bear<WinnersIcon alt='win' src={winIcon}/> <br/>of the show</>}
                    />
                    <WinnerCard
                        nomination={<>The most creative <WinnersIcon alt='win' src={winIcon}/> <br/>look</>}
                        img={creativeWinner}
                        award={t('prizeExclusive')}
                        name="Eva"
                        author="Rita Shesholko"
                        authorLink="/feyushkarita"
                        cardLink="/card/66da9a95033665ef0e94256c"
                        nominationMobile={<>The most creative look <WinnersIcon alt='win' src={winIcon}/></>}
                    />
                    <WinnerCard
                        nomination={<>Toobears Choise from a <WinnersIcon alt='win' src={winIcon}/> <br/>partner Ilka_Bukinist</>}
                        img={choiceWinnerIlka}
                        award={t('prizeBook')}
                        name="Witcher bear"
                        author="Maria Makhovskaya"
                        authorLink="/maryandbears"
                        cardLink="/card/66dabd34a6ab2bcde9bbd55c"
                        nominationMobile={<>Toobears Choise from a<WinnersIcon alt='win' src={winIcon}/> <br/>partner Ilka_Bukinist</>}
                    />
                        <WinnerCard
                        nomination={<>Toobears Choise from <WinnersIcon alt='win' src={winIcon}/> <br/>Toobears</>}
                        img={choiceWinnerToobears}
                        award={<>{t('prizeNextShow')}<br/> {t('prizeNextShow2')}</>}
                        name="Maned wolf"
                        author="Natalya Vasileva"
                        authorLink="/Arleana_family"
                        cardLink="/card/66dace91a6ab2bcde9bc1bed"
                        nominationMobile={<>Toobears Choise from<WinnersIcon alt='win' src={winIcon}/><br/>Toobears</>}
                    />
                </MarketPart>
                <WinnerText>
                    {t('WinnerText')}
                </WinnerText>
        </Root>
    )
}

export default OnlineShowFinish;